import type { Media } from '@cms/types';
import { Link } from '@remix-run/react';
import { cn } from '~/lib/utils';
import { useIsMobile } from '~/modules/shared/providers/device.provider';

type FeaturedCategory = { label: string; url: string; image: Media };

export type FeaturedCategoriesProps = {
  title: string;

  categories: [FeaturedCategory, FeaturedCategory, FeaturedCategory];

  callToAction: string;

  lazyLoad?: boolean;
};

export default function FeaturedCategories({
  categories,
  callToAction,
  title,
  lazyLoad = true,
}: FeaturedCategoriesProps) {
  title = title.trim();

  const isMobile = useIsMobile();

  const getImage = (
    category: FeaturedCategory,
    type: 'horizontal' | 'vertical',
  ) => {
    const width = isMobile ? 300 : 600;
    const height = isMobile ? 200 : type === 'horizontal' ? 400 : 500;
    const quality = isMobile ? 'auto:low' : 'auto:eco';
    const attributes: any = category.image?.attributes;
    const publicId = attributes.provider_metadata.public_id ?? '';
    return `https://res.cloudinary.com/curtains24/image/upload/f_auto/q_${quality}/c_fill,h_${height},w_${width}/${publicId}`;
  };

  const image1 = getImage(categories[0], 'vertical');
  const image2 = getImage(categories[1], 'horizontal');
  const image3 = getImage(categories[2], 'horizontal');

  return (
    <div>
      {title && (
        <div className="sm:flex sm:items-baseline sm:justify-between">
          <h2 className="text-2xl font-bold tracking-tight text-gray-900">
            {title}
          </h2>
        </div>
      )}

      <div
        className={cn(
          'grid grid-cols-1 gap-y-6 sm:grid-cols-2 sm:grid-rows-2 sm:gap-x-6 lg:gap-8',
          {
            'mt-6': !!title,
          },
        )}
      >
        <div className="group aspect-h-1 aspect-w-2 overflow-hidden rounded-lg sm:aspect-h-1 sm:aspect-w-1 sm:row-span-2">
          <img
            src={image1}
            className="object-cover object-center group-hover:opacity-75"
          />
          <div
            aria-hidden="true"
            className="bg-gradient-to-b from-transparent to-black opacity-50"
          />
          <div className="flex items-end p-6">
            <div>
              <h3 className="font-semibold text-white">
                <Link
                  to={categories[0].url}
                  unstable_viewTransition
                  prefetch="intent"
                >
                  <span className="absolute inset-0" />
                  {categories[0].label}
                </Link>
              </h3>
              <p aria-hidden="true" className="mt-1 text-sm text-white">
                {callToAction}
              </p>
            </div>
          </div>
        </div>
        <div className="group aspect-h-1 aspect-w-2 overflow-hidden rounded-lg sm:aspect-none sm:relative sm:h-full">
          <img
            src={image2}
            className="object-cover object-center group-hover:opacity-75 sm:absolute sm:inset-0 sm:h-full sm:w-full"
          />
          <div
            aria-hidden="true"
            className="bg-gradient-to-b from-transparent to-black opacity-50 sm:absolute sm:inset-0"
          />
          <div className="flex items-end p-6 sm:absolute sm:inset-0">
            <div>
              <h3 className="font-semibold text-white">
                <Link
                  to={categories[1].url}
                  unstable_viewTransition
                  prefetch="intent"
                >
                  <span className="absolute inset-0" />
                  {categories[1].label}
                </Link>
              </h3>
              <p aria-hidden="true" className="mt-1 text-sm text-white">
                {callToAction}
              </p>
            </div>
          </div>
        </div>
        <div className="group aspect-h-1 aspect-w-2 overflow-hidden rounded-lg sm:aspect-none sm:relative sm:h-full">
          <img
            src={image3}
            className="object-cover object-center group-hover:opacity-75 sm:absolute sm:inset-0 sm:h-full sm:w-full"
          />
          <div
            aria-hidden="true"
            className="bg-gradient-to-b from-transparent to-black opacity-50 sm:absolute sm:inset-0"
          />
          <div className="flex items-end p-6 sm:absolute sm:inset-0">
            <div>
              <h3 className="font-semibold text-white">
                <Link
                  to={categories[2].url}
                  unstable_viewTransition
                  prefetch="intent"
                >
                  <span className="absolute inset-0" />
                  {categories[2].label}
                </Link>
              </h3>
              <p aria-hidden="true" className="mt-1 text-sm text-white">
                {callToAction}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
