import { Link } from '@remix-run/react';
import { Button } from '~/components/ui/button';
import { ScrollArea, ScrollBar } from '~/components/ui/scroll-area';
import Icon from '~/components/ui/icon';
import type { CallToAction } from '~/modules/shared/types/call-to-action';
import type { BasicProduct } from '~/modules/product/types/basic-product';
import { ProductCard } from '~/modules/product/product-card';

export type FeaturedProductsProps = {
  title: string;

  callToAction: CallToAction;

  products: BasicProduct[];
};

export default function FeaturedProducts({
  products,
  title,
  callToAction,
}: FeaturedProductsProps) {
  return (
    <div className="mt-6 lg:mx-auto lg:max-w-7xl">
      <div className="flex items-center justify-between">
        <Link to={callToAction.url ?? ''} unstable_viewTransition>
          <h2>{title}</h2>
        </Link>
        <Button asChild variant="link">
          <Link
            unstable_viewTransition
            to={callToAction.url ?? ''}
            className="hidden items-center sm:flex"
          >
            {callToAction.action}
            <Icon name="ChevronRight" />
          </Link>
        </Button>
      </div>

      <div className="relative my-8">
        <div className="relative -mb-6 w-full overflow-x-auto pb-6">
          <ScrollArea type="auto">
            <ul
              role="list"
              className="inline-flex space-x-4 lg:grid lg:grid-cols-4 lg:gap-x-8 lg:space-x-0"
            >
              {products.map((product) => (
                <ProductCard
                  categorySlug={callToAction.url?.replace('/', '')}
                  key={product.id}
                  product={product}
                />
              ))}
            </ul>

            <ScrollBar orientation="horizontal" />
          </ScrollArea>
        </div>
      </div>
    </div>
  );
}
