import Icon from '~/components/ui/icon';
import type { KiyohReviewsInfo } from '~/lib/kiyoh/types/kiyoh-reviews-info';
import { Button } from '~/components/ui/button';

export type ReviewsProps = {
  title: string;

  callToAction: string;

  numberOfReviewsText: string;

  items: {
    id: number;

    quote: string;

    attribution: string;
  }[];

  kiyoh: KiyohReviewsInfo;
};

export default function Reviews({
  title,
  items,
  kiyoh,
  numberOfReviewsText,
  callToAction,
}: ReviewsProps) {
  return (
    <section aria-labelledby="testimonial-heading" className="relative py-24">
      <div className="mx-auto max-w-2xl lg:max-w-none">
        <h2
          id="testimonial-heading"
          className="text-2xl font-bold tracking-tight"
        >
          {title}
        </h2>

        <div className="mt-16 space-y-16 lg:grid lg:grid-cols-3 lg:gap-x-8 lg:space-y-0">
          {items.map((review) => (
            <blockquote key={review.id} className="sm:flex lg:block">
              <Icon name="Quote" className="text-gray-400" />
              <div className="mt-8 sm:ml-6 sm:mt-0 lg:ml-0 lg:mt-10">
                <p className="text-lg text-gray-600">{review.quote}</p>
                <cite className="mt-4 block font-semibold not-italic">
                  {review.attribution}
                </cite>
              </div>
            </blockquote>
          ))}
        </div>

        <div className="flex justify-center">
          <div className="mt-24 flex items-center rounded-lg bg-card p-8">
            <div className="flex h-24 w-24 items-center justify-center bg-[url('/images/kiyoh.svg')] bg-contain bg-center bg-no-repeat font-bold lg:mr-8">
              <span className="text-2xl">{kiyoh.rating}</span>
            </div>
            <div className="ml-4 lg:flex lg:items-center">
              <div className="lg:mr-12">
                <span className="block text-lg font-bold">Kiyoh</span>
                <span className="mb-4 block text-lg">
                  {kiyoh.numberOfReviews} {numberOfReviewsText}
                </span>
              </div>
              <Button asChild>
                <a href={kiyoh.url} target="_blank" rel="noopener noreferrer">
                  {callToAction}
                  <Icon name="ChevronRight" />
                </a>
              </Button>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
