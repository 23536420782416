import { cn, seoToMeta } from '~/lib/utils';
import type { FeaturedProductsProps } from '~/modules/home/components/featured-products';
import FeaturedProducts from '~/modules/home/components/featured-products';
import WysiwigViewer from '~/components/ui/wysiwig-viewer';
import type { UspsProps } from '~/modules/home/components/usps';
import Usps from '~/modules/home/components/usps';
import type { ReviewsProps } from '~/modules/shared/components/reviews';
import Reviews from '~/modules/shared/components/reviews';
import type { FeaturedCategoriesProps } from '~/modules/home/components/featured-categories';
import FeaturedCategories from '~/modules/home/components/featured-categories';
import type { DynamicComponent } from '~/modules/shared/types/dynamic-component';
import type { WysiwigContent } from '~/modules/shared/types/wysiwig-content';
import type { MetaFunction } from '@remix-run/node';
import { cache } from '~/lib/cache/client.server';
import { useLoaderData } from '@remix-run/react';
import { homeBackend } from '~/modules/home/backend/home.backend.server';

export const meta: MetaFunction<typeof loader> = ({ data }) => {
  return seoToMeta(data?.seo);
};

export const loader = async () => {
  const homeProps = await cache.getOrCreate('home_page', () =>
    homeBackend.getHomeProps(),
  );
  return homeProps;
};

export type DynamicHomeComponent = DynamicComponent<
  | FeaturedProductsProps
  | WysiwigContent
  | UspsProps
  | ReviewsProps
  | FeaturedCategoriesProps
>;

export type HomePageProps = {
  dynamicContent: DynamicHomeComponent[];
};

const HomePage: React.FC = () => {
  const {
    content: { dynamicContent },
  } = useLoaderData<typeof loader>();

  return [DynamicContent(dynamicContent)];
};

const DynamicContent = (dynamicContent: DynamicHomeComponent[]) => {
  return dynamicContent.map((pc, i) => {
    switch (pc.type) {
      case 'products.featured-products':
        const featuredProducts = pc.content as FeaturedProductsProps;
        return (
          <div key={i} className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
            <FeaturedProducts {...featuredProducts} />
          </div>
        );
      case 'shared.wysiwig-content':
        const content = pc.content as WysiwigContent;
        return (
          <div key={i} className="mx-auto my-16 max-w-7xl px-4 sm:px-6 lg:px-8">
            <WysiwigViewer
              className="max-w-none lg:columns-2 lg:gap-16"
              html={content.html}
            />
          </div>
        );

      case 'shared.reviews':
        const reviews = pc.content as ReviewsProps;
        return (
          <div key={i} className="w-full bg-secondary">
            <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
              <Reviews {...reviews} />
            </div>
          </div>
        );
      case 'categories.featured-categories':
        const featuredCategories = pc.content as FeaturedCategoriesProps;
        return (
          <div
            key={i}
            className={cn('w-full', {
              'my-8': i > 0,
              'mt-4': i === 0,
            })}
          >
            <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
              <FeaturedCategories lazyLoad={i > 0} {...featuredCategories} />
            </div>
          </div>
        );
      case 'home-page.usps':
        const usps = pc.content as UspsProps;
        return (
          <div key={i} className="w-full bg-secondary">
            <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
              <Usps {...usps} />
            </div>
          </div>
        );
    }
  });
};

export const shouldRevalidate = () => false;

export default HomePage;
