import Icon from '~/components/ui/icon';
import type Lucide from 'lucide-react';

export type UspsProps = {
  title: string;

  items: {
    id: number;

    title: string;

    subTitle: string;

    icon: keyof typeof Lucide;
  }[];
};

export default function Usps({ items, title }: UspsProps) {
  return (
    <div className="w-full">
      <div className="rounded-2xl px-6 py-16 sm:p-16">
        <div className="mx-auto max-w-xl lg:max-w-none">
          <div className="text-center">
            <h2 className="text-2xl font-bold tracking-tight text-gray-900">
              {title}
            </h2>
          </div>
          <div className="mx-auto mt-12 grid max-w-sm grid-cols-1 gap-x-8 gap-y-10 sm:max-w-none lg:grid-cols-3">
            {items.map((usp) => (
              <div
                key={usp.id}
                className="text-center sm:flex sm:text-left lg:block lg:text-center"
              >
                <div className="flex justify-center sm:flex-shrink-0">
                  <Icon className="h-10 w-10 text-primary" name={usp.icon} />
                </div>
                <div className="mt-3 sm:ml-6 sm:mt-0 lg:ml-0 lg:mt-6">
                  <h3 className="text-sm font-medium text-gray-900">
                    {usp.title}
                  </h3>
                  <p className="mt-2 text-sm text-gray-500">{usp.subTitle}</p>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}
